import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import styles from "../../css/navigation.module.css";
import homeBrown from "../../images/sidebar/home-brown.svg";
import homeWhite from "../../images/sidebar/home-white.svg";
import composerBrown from "../../images/sidebar/composer-brown.svg";
import composerWhite from "../../images/sidebar/composer-white.svg";
import worksBrown from "../../images/sidebar/works-brown.svg";
import worksWhite from "../../images/sidebar/works-white.svg";
import triviaBrown from "../../images/sidebar/trivia-brown.svg";
import triviaWhite from "../../images/sidebar/trivia-white.svg";
import mapBrown from "../../images/sidebar/map-brown.svg";
import mapWhite from "../../images/sidebar/map-white.svg";
import aboutBrown from "../../images/sidebar/about-brown.svg";
import aboutWhite from "../../images/sidebar/about-white.svg";
import moonEmpty from "../../images/sidebar/moon-empty.svg";
import moonFull from "../../images/sidebar/moon-full.svg";
import Switch from "react-switch";

function SidebarItem(props) {

    const [isHovered, setIsHovered] = useState(false);
    function toggleHovered() {
        setIsHovered(prev => !prev);
        props.text === "Home" ? (!isHovered ? setIcon(homeWhite) : setIcon(homeBrown)) : void (0);
        props.text === "Composers" ? (!isHovered ? setIcon(composerWhite) : setIcon(composerBrown)) : void (0);
        props.text === "Works" ? (!isHovered ? setIcon(worksWhite) : setIcon(worksBrown)) : void (0);
        props.text === "Trivia" ? (!isHovered ? setIcon(triviaWhite) : setIcon(triviaBrown)) : void (0);
        props.text === "Map" ? (!isHovered ? setIcon(mapWhite) : setIcon(mapBrown)) : void (0);
        props.text === "About" ? (!isHovered ? setIcon(aboutWhite) : setIcon(aboutBrown)) : void (0);
    }

    const [icon, setIcon] = useState()
    useEffect(() => {
        props.text === "Home" ? setIcon(homeBrown) : void (0);
        props.text === "Composers" ? setIcon(composerBrown) : void (0);
        props.text === "Works" ? setIcon(worksBrown) : void (0);
        props.text === "Trivia" ? setIcon(triviaBrown) : void (0);
        props.text === "Map" ? setIcon(mapBrown) : void (0);
        props.text === "About" ? setIcon(aboutBrown) : void (0);
        props.text === "Dark Mode" ? setIcon(moonEmpty) : void (0);
    }, [props.text])

    const [checked, setChecked] = useState(false);
    function toggleChecked() {
        let newState = !checked;
        checked ? setIcon(moonEmpty) : setIcon(moonFull);
        setChecked(newState);
        props.toggleDarkMode(newState);
    }

    // local storage dark mode en/dis
    useEffect(() => {
        if (props.text === "Dark Mode") {
            let darkModeEnabled = JSON.parse(localStorage.getItem("darkModeEnabled"));
            setChecked(darkModeEnabled);
            !darkModeEnabled ? setIcon(moonEmpty) : setIcon(moonFull);
            props.toggleDarkMode(darkModeEnabled);
        }
    }, [props.text])

    // resize dark mode switch
    const is1280Px = useMediaQuery("only screen and (max-width : 1280px)");

    // -------------------- Dark Mode -------------------- //
    const sidebarItemStyling = {
        color: props.darkModeEnabled ? "#e8e6e3" : isHovered ? "white" : "black",
        fontSize: props.styling.fontSize,
    }
    const sidebarItemDarkMode = {
        border: props.darkModeEnabled ? "1px solid #242728" : "",
    }
    // -------------------- Dark Mode -------------------- //

    return (
        <div>
            {props.text !== "Dark Mode" && <div style={sidebarItemDarkMode} onMouseEnter={toggleHovered} onMouseLeave={toggleHovered} className={styles.sidebarItem}>
                <img src={icon} width={props.styling.iconSize} />
                <div className={styles.sidebarText} style={sidebarItemStyling}>
                    {props.text}
                </div>
            </div>}

            {props.text === "Dark Mode" && <div style={sidebarItemDarkMode} className={styles.darkModeItem} >
                <img src={icon} width={props.styling.iconSize} />
                <div className={styles.sidebarText}
                    style={sidebarItemStyling}>
                    {props.text}
                </div>
                <div className={styles.darkModeSwitch}><Switch tabIndex="-1" onChange={toggleChecked} checked={checked} onColor="#a52a2a" uncheckedIcon={null} checkedIcon={null} handleDiameter={is1280Px ? 12 : 26} height={is1280Px ? 14 : 28} width={is1280Px ? 28 : 56} /></div>
            </div>}
        </div>

    )
}

export default SidebarItem;